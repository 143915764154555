
import BasicTablePage from '@/components/BasicPageLayout/BasicTablePage.vue'
import { defineComponent, nextTick, onMounted, reactive, ref, toRef, watch } from 'vue'
import usePagination from '@/utils/composables/usePagination'
import { UserRole } from '@/types/Role'
import { Buttons, Inputs, SpanMethod, TableColumns } from '@/types/BasicComponent'
import { formatTime, formatDate, getTableColumnsSummary } from '@/utils/common'
import dayjs from 'dayjs'
import BasicTablePrintAndExport from '@/components/BasicPageLayout/BasicTablePrintAndExport.vue'
import { useRouter } from 'vue-router'
import { getStockNumList, StockNumListQueryParams } from '@/api/production'

export default defineComponent({
  components: {
    BasicTablePage,
    BasicTablePrintAndExport
  },
  setup() {
    const router = useRouter()
    const pagination = usePagination()
    const searchData = reactive<StockNumListQueryParams>({})
    const tableData = ref<AnyArray>([])
    const selectCount = ref(0)

    const exportRef = ref()

    const getTableData = async () => {
      const resp = await getStockNumList(Object.assign({ page: pagination.currentPage, limit: pagination.pageSize }, searchData))
      const respData = resp.data.data

      tableData.value = respData
      tableData.value.forEach((td, idx) => td.index = pagination.pageOffset + idx)

      pagination.total = resp.data.data.count
    }
    const mySummaryMethod = (scope: AnyObject) => {
      let props = ['initNum', 'initAmount', 'intoNum', 'intoAmount', 'outOfNum', 'outOfAmount', 'endNum', 'endAmount']
      return getTableColumnsSummary(props, scope.columns, scope.data)
    }
    
    pagination.setCallback(getTableData)

    onMounted(() => {
      getTableData()
    })

    const searchInputs = reactive<Inputs>([
      {
        label: '时间',
        type: 'group',
        divider: '至',
        inputs: [
          {
            type: 'date',
            model: toRef(searchData, 'startTime'),
            clearable: false,
            placeholder: '开始日期',
            width: '150px',
          }, {
            type: 'date',
            model: toRef(searchData, 'endTime'),
            clearable: false,
            placeholder: '结束日期',
            width: '150px'
          }
        ]
      }
    ])

    const searchButtons = reactive<Buttons>([
      {
        label: '查询',
        icon: 'el-icon-search',
        onClick: getTableData
      }, {
        label: '导出',
        icon: 'el-icon-download',
        onClick: async () => {
          nextTick(() => exportRef.value.doExport())
        }
      }
    ])

    const tableColumns = reactive<TableColumns>([
      {
        prop: 'goodsType',
        label: '商品类别',
        width: '80px',
      }, {
        prop: 'goodsName',
        label: '商品名称',
        minWidth: '140px'
      }, {
        prop: 'goodsUnit',
        label: '单位',
        minWidth: '80px',
      }, {
        label: '期初余额',
        columns: [
          {
            prop: 'initNum',
            label: '数量',
            minWidth: '120px',
          }, {
            prop: 'initPrice',
            label: '单价',
            minWidth: '120px',
          }, {
            prop: 'initAmount',
            label: '金额',
            minWidth: '120px',
          }, 
        ]
      }, {
        label: '本期入库',
        columns: [
          {
            prop: 'intoNum',
            label: '数量',
            minWidth: '120px',
          }, {
            prop: 'intoPrice',
            label: '单价',
            minWidth: '120px',
          }, {
            prop: 'intoAmount',
            label: '金额',
            minWidth: '140px',
          }, 
        ]
      }, {
        label: '本期出库',
        columns: [
          {
            prop: 'outOfNum',
            label: '数量',
            minWidth: '120px',
          }, {
            prop: 'outOfPrice',
            label: '单价',
            minWidth: '120px',
          }, {
            prop: 'outOfAmount',
            label: '金额',
            minWidth: '140px',
          }, 
        ]
      }, {
        label: '期末数据',
        columns: [
          {
            prop: 'endNum',
            label: '数量',
            minWidth: '120px',
          }, {
            prop: 'endPrice',
            label: '单价',
            minWidth: '120px',
          }, {
            prop: 'endAmount',
            label: '金额',
            minWidth: '120px',
          }, 
        ]
      }
    ])

    return {
      getTableData,
      formatTime,
      formatDate,
      exportRef,
      searchInputs,
      searchData,
      searchButtons,
      tableColumns,
      pagination,
      tableData,
      UserRole,
      mySummaryMethod,
      selectCount
    }
  }
})
